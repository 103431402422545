import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import {
  CustomerGlobal,
  MediaQueryProvider,
  bootstrap,
  configureCustomerStore,
} from '@jane/monolith/marketplace';
import { ErrorBoundary } from '@jane/shared-ecomm/components';
import { config } from '@jane/shared/config';
import { asyncFlagProvider } from '@jane/shared/feature-flags';
import { EcommAppProvider } from '@jane/shared/providers';
import '@jane/shared/reefer/index.css';

const App = lazy(() => import('./app/App').then((a) => ({ default: a.App })));

const store = configureCustomerStore();

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

(async () => {
  const FlagProvider = await asyncFlagProvider(config.launchDarklyClientID);
  await bootstrap({ isMarketplace: true });
  root.render(
    <ErrorBoundary whiteLabel={false} withReeferThemeProvider>
      <EcommAppProvider appMode="default">
        <FlagProvider>
          <ReduxProvider store={store}>
            <CustomerGlobal />
            <MediaQueryProvider>
              <App />
            </MediaQueryProvider>
          </ReduxProvider>
        </FlagProvider>
      </EcommAppProvider>
    </ErrorBoundary>
  );
})();
